%status {
    padding: 2px 10px;
    text-align: center;
    border-radius: 4px;
}

%button-red {
    border-radius: 5px;
    background-color: #ef5350;   
    margin: 0 10px;   
    height: 60px;      
    width: 138px;      
    border: 0px;
}

.card-management-container {
    background-color: #f8f8f8;
    padding-left: 0;
    padding-right: 0;

    .header-container {
        background-color: #ffffff;
        width: 100%;
    }

    .page-container {
        justify-content: center;
        height: 105px;

        .page-title-wrapper {
            display: flex;
            justify-content: space-between;
            height: 105px;

            .button-container {
                display: flex;
                align-items: center;

                .add-user {
                    @extend %button-red;
                }

                .download {
                    background-color: #ffffff;
                    height: 60px;
                    margin: 0 10px; 
                    height: 60px;  
                    width: 138px;
                    color: #888888;
                    border: 0px;
                }
            }
            
            .title-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                .page-title {
                    font-size: 22px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.27;
                    letter-spacing: normal;
                    text-align: left;
                    color: #070707;
                }
            
                .page-sub-title {
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.42;
                    letter-spacing: normal;
                    text-align: left;
                    color: #888888;

                    a {
                        color: #888888;
                      }
                }
            }
        } 
    }

    .user-body-container {
        background-color: #ffffff;
        width: 100%;
        padding: 20px;
        margin-bottom: 30px;
        min-height: 70%;

        .table-action-container {
            grid-template-columns: 125px 100px;
            align-items: center;
        }

        .table-action-container.per-page {
            grid-template-columns: 100px auto;
            justify-content: flex-end;
        }

        .table-container {
            display: block;
            width: 100%;
            overflow-x: hidden;

            .add-card-table {
                width: 200vw;

                .input-card-order {
                    min-width: 150px;
                }
                
                thead th {
                    white-space: nowrap;
                }

                thead tr th {
                    vertical-align: top;
                }

                tbody tr td {
                    input[type=number]::-webkit-inner-spin-button,
                    input[type=number]::-webkit-outer-spin-button { 
                        -webkit-appearance: none;
                    }
                }

                tbody tr:hover {
                    background: #F2F2F2;
                  }

                .multi-icon {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
      
                    svg {
                        cursor: pointer;
                        fill: #606060;
                    }

                    svg:hover {
                        fill: #FF0100;
                    }
                    
                    .overlay-container {
                        position: relative;
                        margin-left: 21px;
      
                        .overlay {
                            position: absolute;
                            left: 15px;
                            top: -15px;
                            padding: .5em 1em;
                            background: white;
                            display: none;
                            z-index: 2;
                            width: 200px;
                        }
                        .overlay.show {
                            display: block
                        }
                    }
                }
            }
        }

        .threedots {
            font-size: 15px;
            color: #ef5350;
            cursor: pointer;
        }
        .threedots:hover {
            color: #004444
        }

        .filter-user {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-bottom: 21px;
        }

        .table-wrapper {
            display: grid;
            grid-template-columns: 650px 100%;
            width: 100%;
            max-height: 500px;
            overflow-y: auto;

            .header-table {
                font-size: 13px;
                font-weight: bold;
                padding: .5em 1em;
                display: flex;
                align-items: center;
                word-wrap: break-word;
                min-width: 52px;
                height: 50px;

                .sort-column {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    width: 100%;
                }
            }

            .body-table {
                padding: 1.75em 1em 1em 1em;
                // display: flex;
                // align-items: flex-start;
                font-size: 14px;
                height: 90px;
                min-width: 52px;

                .custom-control {
                    z-index: 0;
                }

                .multi-icon {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    svg {
                        cursor: pointer;
                    }

                    svg:hover {
                        fill: #FF0100;
                    }
                    
                    .overlay-container {
                        position: relative;
                        margin-right: 21px;

                        .overlay {
                            position: absolute;
                            left: 15px;
                            top: -15px;
                            padding: .5em 1em;
                            background: white;
                            display: none;
                            z-index: 2;
                            width: 200px;
                        }
                        .overlay.show {
                            display: block
                        }
                    }
                }
            }

            .body-table.icon {
                padding: 1.75em 0 1em 0;
            }

            .sticky-table-container {
                position: sticky;
                top: 0;
                left: 0;
                flex-grow: 0;
                flex-shrink: 0;
                width: 650px;
                background: white;
                z-index: 3;

                .sticky-table {
                    display: grid;
                    grid-template-columns: 55px 55px 140px 175px 225px;
                    border-top: 1px solid #dee2e6;
                    border-right: 1px solid #dee2e6;
                }

                .sticky-table.header {
                    position: sticky;
                    z-index: 3;
                    top: 0;
                    border-right: 1px solid #000000;
                }

                .sticky-table.Inactive {
                    background: #F2F2F2;
                    // border-right: 1px solid #dee2e6;
                }
                
                .sticky-table.Active {
                    // border-right: 1px solid #dee2e6;
                }
                
                .sticky-table.on-hover {
                    background: #F2F2F2;
                }
            }

            .scroll-table {
                position: relative;
                top: 1px;
            }

            .scroll-table thead {
                position: sticky;
                top: 1px;
                z-index: 2;
            }

            .scroll-table thead th {
                border: 0;   
            }

            .scroll-table th, .scroll-table td {
                min-width: 200px;
            }

            .scroll-table td {
                padding: 1.625rem .75rem;
            }

            .scroll-table .on-hover td {
                background: #F2F2F2;
            }

            .scroll-table .Inactive td {
                background: #F2F2F2;
            }

            .scroll-table .header {
                color: #ffffff;
                background-color: #000000;
                font-size: 14px;
                height: 50px;
                white-space: nowrap;
                z-index: 2;
            }

            .scroll-table td {
                .Active {
                    background: #E5FAF2;
                    border: 1px solid #72C4AF;
                    @extend %status;
                }
                .in_process {
                    background: #F8F8F8;
                    border: 1px solid #C7C7C7;
                    @extend %status;
                }
                .Inactive {
                    background: #FAF6E5;
                    border: 1px solid #D5C893;
                    @extend %status;
                }
                .Declined {
                    background: #FAE5FA;
                    border: 1px solid #DEAEDE;
                    @extend %status;
                }
            }

            .scroll-table {
                .react-tagsinput {
                    padding-top: 0 !important;

                    .react-tagsinput-input {
                        margin-bottom: 0 !important;
                    }

                    .react-tagsinput-tag {
                        margin-bottom: 5px !important;
                        margin-top: 5px !important;
                        font-size: 13px !important;
                        padding: 1px 10px !important;
                    }

                    &.disabled {
                        background: #e9ecef;
                    }
                }
            }
            

            .scroll-table-container {
                width: 200vw;
                flex-grow: 0;
                flex-shrink: 0;
                background: white;
                // overflow-y: hidden;

                // .scroll-table {
                //     display: grid;
                //     grid-template-columns: 1fr 1fr 1fr 1fr 1.25fr 1fr 1fr 1fr 1fr 1.5fr 1.5fr 1fr .75fr .75fr .75fr 1fr 1fr 1fr 1fr 1fr 1fr;
                //     border-top: 1px solid #dee2e6;

                //     .body-table {
                //         padding: 1.1em;
                //         word-wrap: break-word;
                //         vertical-align: middle;
                //     }
                // }

                // .scroll-table.header {
                //     position: sticky;
                //     top: 0;
                //     z-index: 1;
                // }
            }
        }

        .stepper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-bottom: 20px;
            .stepper-inner {
                width: 50%;
            }
        }

        .add-user {
            @extend %button-red;
            margin: 10px 0;
            height: auto !important;
        }

        .button-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2em 0 0 0;
    
            .cancel {
                color: #888888;
                border: 1px solid #888888;
                margin-right: 30px;
            }

            .cancel:hover {
                color: #888888;
                border: 1px solid #888888;
                background: white;
            }
        }
    }

    .header-table {
        color: #ffffff;
        background-color: #000000;
    }

    ul {
        list-style-type: none;
        padding-left: 0px;
    }

    .handle-loading {
        width: 100%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    th {
        font-size: 13px;
    }

    td {
            background-color: white;
            font-size: 13px;
            color: #606060;
        }

    .pagination-user {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        // margin: 1em 0 0 0;
       

        .page-item.active .page-link {
            background-color: #ff0100;
            border-color: #ff0100;
            color: #ffffff;
            z-index: 0;
        }

        .page-link {
            color: #606060;
        }
    }
}

.modal-container-card {
    .active-status {
        color: #72C4AF;
    }
    
    .header-form {
        background-color: #f0f0f0;
        padding-left: 2.25rem;
    }
  
    label.form-label {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
    }

    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2em 0 0 0;

        .action-button {
            padding: .5em;
            width: 100px;
            font-weight: 400;
        }
        .action-button.cancel {
            color: #888888;
            border: 1px solid #888888;
            margin-right: 30px;
        }
        .action-button.cancel:hover {
            color: #888888;
            border: 1px solid #888888;
            background: white;
        }
    }
    .edit-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
    }

        .invalid-feedback {
            text-align: right;
        }

        .stepper {

                a {
                    line-height: 26px !important;
                }
    
                span {
                    line-height: 26px !important;
                }
          
        }

       

}

.option-delivery {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .button-option {
        margin: 0 5px;
        padding: 10px 5px !important;
        font-weight: 600;
        border: #d0d4db 1px solid;
        border-radius: 5px;
        cursor: pointer;
        &.active {
            border: #6dd085 1px solid;
        }
        .icon {
            color: #d0d4db;
            &.active {
                color: #6dd085;
            }
        }
    }
}

.pickup-address {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 20px;
    background-color: #f8f8f8;
    margin-bottom: 5px;
    p {
        margin-bottom: 0 !important;
    }
}

.card-review-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .card-review-icon {
        margin: 2em 0;
        background: #ef5350;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    .review-info {
        color: #ef5350;
    }
}

.card-delivery-address-container {
    .virtual-card-description {
        background: #f0f0f0;
        border-radius: 5px;
        padding: 20px;
    }
    .title-form {
        font-weight: 600;
    }
}

#card-table::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
}

#card-table::-webkit-scrollbar-thumb {
    border-radius: 4px;
    height: 7px;
    background-color: #ef5350;
    z-index: 1;
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.table-wrapper.scroller {
    scrollbar-color: #ef5350 rgba(255,255,255,.5);
    scrollbar-width: thin;
}

.tooltip-inner{
    text-align: left !important;
}

.react-tagsinput > span:first-child {
    display: flex;
    align-items: center;
}

.react-tagsinput .react-tagsinput-tag {
    display: flex;
    align-items: center;
}

.react-tagsinput .react-tagsinput-tag .react-tagsinput-remove {
    margin-left: .5em;
}

.no-max-height{
    max-height: 1000px !important;
}