.menu-container {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    .menu {
        display: flex;
        align-items: center;
    }

    a {
        display: flex;
        align-items: center;
        color: #000000;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: left;
   
    }

    .nav-item.dropdown .dropdown-toggle.nav-link.active {
        color: #ff0100;
    }
    
    .nav .menu.nav-link.active {
        color: #ff0100;
    }
    
    .dropdown-item.active {
        color: #ff0100;
        background: white;
    }

    a:hover {
        color: #ff0100;
    }

    .header-icon-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;   
        margin: 0 10px;

        .dropdown-item:active {
            background: #e9ecef;
        }
    }

    .header-icon-container:hover {
        color: #ff0100;
    }

    .header-icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 10px;   
    }

}