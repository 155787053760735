@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

body {
  font-family: 'Source Sans Pro', arial !important;
  margin: 0;
  color: #212529;
  background-color: #fff;
  min-height:100% !important;
}

.button-container {
  font-weight: 700 !important;
}

.button-container:focus {
  box-shadow: none !important;
}

.btn {
  font-weight: 400 !important;
}

.btn:focus {
  box-shadow: none !important;
}

label.custom-control-label::before {
  box-shadow: none !important;
}

.custom-switch .custom-control-label::before {
  border-color: #D0D0D0 !important;
  opacity: 1;
  background-color: #D0D0D0 !important;
  cursor: pointer;
}

.toggle-disabled.custom-switch .custom-control-label::before {
  cursor: not-allowed;
}

.custom-switch .custom-control-label::after {
  background-color: #888888 !important;
  cursor: pointer;
}

.toggle-disabled.custom-switch .custom-control-label::after {
  cursor: not-allowed;
}

.custom-switch .custom-control-input:checked~.custom-control-label::before {
  border-color: #BADFBA !important;
  opacity: 1;
  background-color: #BADFBA !important;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: #139316 !important;
}

.table-responsive {
  min-height: 200px !important;
}

.table-responsive tbody tr:hover {
  background: #F2F2F2;
}

.table-responsive thead tr th {
  vertical-align: top;
}

.table-responsive tbody tr td {
  vertical-align: middle;
}

.table-responsive .show.dropdown .dropdown-menu.show .dropdown-item:hover {
  color: #ff0100;
}

.table-responsive .show.dropdown .dropdown-menu.show .dropdown-item:active {
  background: #ff0100;
  color: white;
}

table thead tr .sort-column {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer
}

.table thead th {
  border-bottom: 0 !important;
}

.show.dropdown .dropdown-menu.show {
  box-shadow: 0px 0px 10px -3px rgb(0 0 0 / 50%);
}

.required:after {
  content: '*';
  color: red;
  margin-left: 3px;
  top: -4px;
}

.big-primary-button {
  border-radius: 5px;
  background-color: #ef5350;   
  margin: 0 10px;   
  padding: 0 1.25em;
  border: 0px;
  color: white;
  cursor: pointer;
  height: 60px;
}

.big-primary-button:hover{
  background: #CF1C00;
}

.primary-button {
  border-radius: 5px;
  background-color: white;   
  margin: 0 10px;   
  padding: 0 1.25em;
  border: 1px solid #ef5350;
  color: #ef5350;
  cursor: pointer;
  height: 45px;
}

.primary-button:hover {
  background: #ef5350;
  color: white;
}

.secondary-button {
  border-radius: 5px;
  background-color: white;   
  margin: 0 10px;   
  padding: 0 1.25em;
  border: 1px solid #888888;
  color: #888888;
  cursor: pointer;
  height: 45px;
}

.secondary-button:hover {
  background: #888888;
  color: white;
}

.primary-button, .secondary-button {
  min-width: 150px;
}

.primary-button.filter {
  height: 38px;
  min-width: 50px;
}

.search-button {
  height: 38px;
  background: #ef5350;
  color: white;
  padding: 0 1em; 
  border: 0;
  border-radius: 5px;
}

.search-button:hover {
  background: #CF1C00;
}

.success-button {
  background: #32B04E;
  color: white;
  border-radius: 5px;
  margin: 0 10px;   
  padding: 0 1.25em;
  cursor: pointer;
  height: 45px;
  border: 0;
}

.delivery-button {
  display: flex;
  align-items: center;
  padding: .75em 1em;
  border: 1px solid #CED4DA;
  background: white;
  border-radius: .25rem;
  font-weight: 600;
  cursor: pointer;
}

.delivery-button svg {
  margin-right: .5em;
}

.delivery-button.selected { 
  border-color: #6DD084; 
}

.delivery-button:hover { 
  border-color: #6DD084; 
}

.disabled-button {
  border-radius: 5px;
  background-color: #EDEDED;   
  margin: 0 10px;   
  padding: 0 1.25em;
  border: 1px solid #EDEDED;
  color: #888888;
  cursor: not-allowed;
  height: 45px;
}

.download-button { 
  border-radius: 5px;
  background-color: white;   
  margin: 0 10px;   
  padding: 0 1.25em;
  border: 1px solid white;
  color: #888888;
  cursor: pointer;
  height: 60px;
}

.table-filter-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 175px));
  grid-gap: 21px;
  align-items: end;
}

.table-action-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
  grid-gap: 21px;
}

.react-daterange-picker {
  width: 100%;
}

.react-daterange-picker__wrapper {
   padding: .375rem .75rem;
   border: 1px solid hsl(0, 0%, 80%) !important;
   color: #495057;
   border-radius: .25rem;
}

.react-daterange-picker__inputGroup {
  min-width: unset !important;
}

.react-calendar {
  border: 1px solid hsl(0, 0%, 80%) !important;
  font-size: 14px;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: #ef5350 !important;
  color: white;
}

.react-calendar__tile--active {
  background-color: #ef5350 !important;
}

.stepper .stepper-inner div > div > div > div {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.scrollbar {
  background: #FF2600 !important;
}

.react-tagsinput--focused {
  border-color: hsl(0, 0%, 80%) !important;
}

.react-tagsinput-tag {
  border: 0 !important;
  background: #FF0100 !important;
  color: white !important;
  padding: 5px 10px !important;
  font-size: 14px !important;
}

.react-tagsinput-remove {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.react-tagsinput-remove:hover {
  color: white;
  text-decoration: none; 
}

.rc-time-picker {
  width: 100%;
}
.rc-time-picker-input {
  height: 38px !important;
  border-color: #cccccc !important;
  font-size: 16px !important;
}

.rc-time-picker-panel-inner {
  font-size: 16px !important;
}

.rc-time-picker-panel-select {
  font-size: 16px !important; 
}

.rc-time-picker-panel-narrow {
  max-width: unset !important;
}

.rc-time-picker-panel-select {
  width: 82px !important;
}

.rc-time-picker-panel-select li {
  text-align: center !important;
  padding: 5px 0 !important;
}

.popup-info .header-table {
  background: #000000;
  color: #ffffff;
  font-size: 14px;
}

.popup-info .popup-body-table {
  display:block;
  max-height:150px;
  overflow:auto;
}

.popup-info .popup-header-table, .popup-body-table .popup-row-table {
  display:table;
  width:100%;
  table-layout:fixed;
}

.popup-info .popup-body-table .popup-row-table:nth-child(even) {
  background: #F4F4F4;
}

.popup-info .popup-body-table .popup-row-table td {
  border: 0;
}

.custom-table {
  display: block;
  empty-cells: show;
}

.custom-table .custom-header-table, .custom-table .custom-body-table {
  position:relative;
  display: block;
  width:100%;
}

.custom-table .custom-body-table {
  display:block;
  max-height:50vh;
  overflow:auto;
}

.custom-table tr {
  width: 100%;
  display:flex;
}

.custom-table td, .custom-table th {
  flex-basis:100%;
  flex-grow:2;
  display: block;
}

.custom-table td {
  word-break: break-word;
}

.custom-table.w-threedots td:first-child, .custom-table.w-threedots th:first-child {
  flex-basis: 20%;
  flex-grow: 1;
}

.custom-table .custom-body-table::-webkit-scrollbar {
  width: 5px;
}

.custom-table .custom-body-table::-webkit-scrollbar-thumb {
  background-color: #FF2600;
  border-radius: 50px;
}

.react-tagsinput-input {
  min-width: 80px;
  border: 0 !important;
  width: unset !important;
}

.react-tagsinput-input.tagsinput-zipcode{
  width: 260px !important;
}