%title {
  font-size: 20px !important;
  font-weight: 600;
  margin-top: 20px;
}

.popup-action {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 99;
  position: fixed;
  top: 0;

  .popup-container {
    width: 450px;
    background-color: #fff;
    border-radius: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    padding: 0;

    .popup-body {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 1em;
      .icon-container {
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        background-color: rgb(255, 56, 56);
        margin: 0;
        padding: 0;
        .icon-popup {
          color: white;
          font-size: 30px;
        }
      }
      
      .success {
        @extend %title;
        color: #8bc34a;
      }
  
      .failure {
        // @extend %title;
        font-size: 18px;
        margin-top: 20px;
        color: rgb(253, 68, 68);
      }
  
      .text {
        font-size: 14px;
        color: #000;
      }
    }

    .popup-header {
      background-color: rgb(223, 223, 223);
      width: 100%;
      height: 40px;
      margin-top: -1px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2em 1.5em;
      border-radius: 10px 10px 0 0;

      .title {
        font-size: 18px;
        font-weight: 700;
      }

      .close-btn {
        font-size: 16px;
        cursor: pointer;
      }
    }

    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 0 0 0;

      .cancel {
          margin-right: 30px;
      }
  
      .btn-outline-danger {
          color: #ff0100;
          border-color: #ff0100;
          width: 120px;
      }
  
      .btn-outline-danger:hover {
          background-color: #ffffff;
      }
  
      .btn-outline-light {
          color: #888888;
          border-color: #888888;
          width: 120px;
      }
  
      .btn-outline-light:hover {
          background-color: #ffffff;
      }
  
      .css-12jo7m5 {
          background-color: #ff0100;
          color: #ffffff;
      }
  
      .css-xb97g8 {
          background-color: #ff0100;
          color: #ffffff;
      }
  
      .col-address {
          padding-left: 5px;
          padding-right: 5px;
      }
    }
  }
}
