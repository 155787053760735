.mobile-menu{
    display: none;
}

button{
    &:disabled{
        cursor: not-allowed;
    }
}

@media only screen and (max-width: 600px) {
    .setting-container{
        align-items: center;
        padding-left: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100% !important;
    }
    .header-profile{
        display: none !important;
    }
    .dropdown-logout, .menu-container{
        display: none !important;
    }
    .page-title-wrapper{
        flex-direction: column;
        height: 300px;
        justify-content: center;
    }
    .page-container{
        margin-bottom: 100px;

        .page-title{
            text-align: center !important;
            margin-bottom: 30px;
        }
    }
    .menu-wrapper{
        display: none;
    }
    .mobile-menu{
        display: block;
    }
    
    .header-icon-container {
        display: flex;
        flex-direction: row;
        justify-content: unset;
        align-items: center;   
        margin: 0 10px;

        .dropdown-item:active {
            background: #e9ecef;
        }

        a {
            display: flex;
            align-items: center;
            color: #000000;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.44;
            letter-spacing: normal;
            text-align: left;
            margin-left: 10px;
            margin-right: 10px  ;
        }

        .header-icon{
            margin-right: 10px;
        }
    }

    .dashboard-card{
        margin-top: 15px;
        margin-bottom: 15px;
    }
    
    .dashboard-download-button{
        margin-top: 10px !important;
    }
    
}
