
    .footer-info {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        padding: 18px 0;
        background-color: #000000;
    }

    .customer-service-container {
        border-radius: 5px;
        background-color: #f0f0f0;
        padding: 20px;
        display: flex;
        position: relative;
        justify-content: center;

        .customer-service-info {
            margin-left: 20px;
        }
    }