.card-template-container {
    background-color: #f8f8f8;
    padding-left: 0;
    padding-right: 0;

    .header-container {
        background-color: #ffffff;
        width: 100%;
    }

    .page-container {
        justify-content: center;
        height: 105px;

        .page-title-wrapper {
            display: flex;
            justify-content: space-between;
            height: 105px;

            .button-container {
                display: flex;
                align-items: center;

                .add-user {
                    border-radius: 5px;
                    background-color: #ef5350;   
                    margin: 0 10px;   
                    height: 60px;      
                    width: 150px;      
                    border: 0px;
                }

                .download {
                    background-color: #ffffff;
                    height: 60px;
                    margin: 0 10px; 
                    height: 60px;  
                    width: 138px;
                    color: #888888;
                    border: 0px;
                }
            }
            
            .title-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                .page-title {
                    font-size: 22px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.27;
                    letter-spacing: normal;
                    text-align: left;
                    color: #070707;
                }
            
                .page-sub-title {
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.42;
                    letter-spacing: normal;
                    text-align: left;
                    color: #888888;

                    a {
                        color: #888888;
                      }
                }
            }
        } 
    }

    .user-body-container {
        background-color: #ffffff;
        width: 100%;
        padding: 20px;
        margin-bottom: 30px;
        min-height: 70%;

        .threedots {
            font-size: 15px;
            cursor: pointer;
        }
        .threedots:hover {
            color: #004444
        }

        .filter-user {
            width: 300px;
            margin-bottom: 21px;
        }
    }

    .header-table {
        color: #ffffff;
        background-color: #000000;
        font-size: 14px;
    }

    ul {
        list-style-type: none;
        padding-left: 0px;
        
    }

    .handle-loading {
        width: 100%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    td {
        // background-color: #fafafa;
        font-size: 16px;
        color: #606060;
        font-size: 14px;
    }
    .pagination-user {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 50px;
       

        .page-item.active .page-link {
            background-color: #ff0100;
            border-color: #ff0100;
            color: #ffffff;
        }

        .page-link {
            color: #606060;
        }
    }
}

.modal {
    z-index: 50 !important;
}

.modal-backdrop {
    z-index: 49 !important;
}

.modal-container-template {
    .header-form {
        background-color: #f0f0f0;
        padding-left: 2.25rem;
    }

    .modal-container-with-background {
        background-color: rgb(242, 242, 242);
        padding: 20px;
    }

    input {
        padding: 15px;
        border-radius: 2px;
        height: 52px;
        border: solid 1px #ced4da;
        background-color: #ffffff;
    }
    
    select {
        padding: 15px;
        border-radius: 2px;
        height: 52px;
        border: solid 1px #ced4da;
        background-color: #ffffff;
    }

    #card-type {
        border-radius: 2px;
        height: 52px !important;
        border: solid 1px #ced4da;
        background-color: #ffffff; 
    }

    #card-code {
        border-radius: 2px;
        height: 52px !important;
        border: solid 1px #ced4da;
        background-color: #ffffff; 
    }

    .radio-template {     
        display: flex;
    }
  
    label.form-label {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
    }

    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 0;

        .cancel {
            margin-right: 30px;
        }
    
        .btn-outline-danger {
            color: #ff0100;
            border-color: #ff0100;
        }
    
        .btn-outline-danger:hover {
            background-color: #ffffff;
        }
    
        .btn-outline-light {
            color: #888888;
            border-color: #888888;
            width: 120px;
        }
    
        .btn-outline-light:hover {
            background-color: #ffffff;
        }
    
        .css-12jo7m5 {
            background-color: #ff0100;
            color: #ffffff;
        }
    
        .css-xb97g8 {
            background-color: #ff0100;
            color: #ffffff;
        }
    
        .col-address {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    .edit-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
    }

        .invalid-feedback {
            text-align: right;
        }

        .custom-switch {
            z-index: 0;
        }
    .custom-control-label {
        color: #000 !important;
    }
}

.card-template-detail-modal {
    .header-form.modal-header {
        padding: 1rem
    }
    .card-template-detail-table {
        border: 1px solid #dee2e6;

        tr:nth-child(even) {
            background: #F4F4F4;
        }
        tr:nth-child(odd) {
            background: #FAFAFA;
        }
        tr td {
            border: 0
        }
    }
}

.show{
    display: block
}

.hidden{
    display: none;
}

.custom-table.card-template-table td:nth-child(2), .custom-table.card-template-table th:nth-child(2) {
    flex-basis: 40%;
    flex-grow: 1;
}

.custom-control-label[for=odometer]{
    color: #000 !important;
}