.modal-message-container {
    .header-form {
        background-color: #f0f0f0;
        padding-left: 1rem;
        font-size: 22px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: normal;
        text-align: left;
        color: #070707;
    }

    .modal-body {
        padding: 60px 50px;
    }

    .reset-message {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;  
        letter-spacing: normal;
        text-align: center;
        color: #ff0100;

        .click-message {
            line-height: 1.18;
            font-size: 16px;
            color: #000;
        }

        .message {
            font-size: 12px;
            color: #000000;
        }
    }

    .eclipse {
        flex-wrap: wrap;
        width: 62px;
        height: 62px;
        padding: 12px;
        background-color: #ff0100;
        border-radius: 50px;
        margin-bottom: 21px;
        justify-content: center;
        align-items: center;
      }
}