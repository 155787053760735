Nav {
    .dropdown-logout {
        .dropdown-menu {
            right: 0 !important;
            left: unset;
        }

        .show {
            right: 0 !important;
        }
    }

    .logo-auth {
        height: 53px;
     }

     .header-signup-button {
        // width: 123px;
        height: 43px;
        border-radius: 5px;
        background-color: #ff0100;
        border: none;
        justify-content: center;
        align-items: center;
        span {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
        }
     }
     .header-signup-button:hover {
         background-color: #000000;
     }

     .navbar-info {
         display: flex;
         align-items: center;
         font-size: 16px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.63;
         letter-spacing: normal;
         text-align: left;
         color: #000000 !important;
         cursor: default;
     }
}

.header-auth {

    .navbar {
        padding: 18px 0 !important;
    }

    .dropdown-logout {
        margin-top: 3px;
    }

    .header-profile {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .user {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: normal;
            color: #000000;
        }

        .account {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: normal;
            color: #888888;
        }
    }

    .setting-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 350px;
        margin-right: 25px; 
        position: relative;

        select {
            appearance: none;
            cursor: pointer;
        }
    }

    .setting-container::after {
        // content: '\2304';
        // position: absolute;
        // top: -2px;
        // right: 10px;
        // font-size: 20px;
    }

    .select-account {
        position: absolute;
        width: 300px;
        z-index: 2;
        top: 100px;
        background-color: #ffffff;
        padding: 10px 10px;
        border: 1px solid #000000;

        .accounts {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .account {
                display: flex;
                flex-direction: column;
            }
            .status {
                display: flex;
                justify-content: center;
            }
        }
        
    }
}

.header-logout {
    .threedots {
        font-size: 30px;
        margin-left: 10px;
        color: #ced4da;
        cursor: pointer;
    }
    .threedots:hover {
        color: #004444
    }
}