.term-container {
    padding: 20px;
    margin: 0 60px;
    background-color: #fafafa;
    text-align: justify;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #000000;

    .term-title {
        font-weight: bold;
        font-size: 26px;
        padding: 30px 0;
    }

  
}

.accept-container {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    margin-top: 40px;

    .accept-button {
        padding: 13px 36px 12px;
        border-radius: 23px;
        background-color: #ef5350;
        color: #ffffff;
        margin-bottom: 15px;
    }

    .cancel-button {
        color: #838383;
    }

    .cancel-button:hover {
        cursor: pointer;
    }
}

.term-and-condition {
    padding: 20px 0;
    margin: 0 60px;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}